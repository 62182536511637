import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../service/profile.service';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import {LoaderService} from "../../../../../shared/components/loader/service/loader.service";

@Component({
  selector: 'advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss'],
})
export class AdvancedComponent implements OnInit {
  isGenerate = false;
  api_user_token = '';
  redirectResponse = false;
  redirectString = '';
  redirectParams = '';
  constructor(
    private profileService: ProfileService,
    private generalService: GeneralService,
    private loaderService: LoaderService,

  ) {}

  ngOnInit(): void {
    this.isGenerate =
      this.api_user_token !== '' &&
      this.api_user_token !== null;
    this.loaderService.show();
    this.generalService.getUserDetails().subscribe((res: any) => {
      this.api_user_token = res.message.api_user_token;
      this.isGenerate =
        res.message.api_user_token !== '' &&
        res.message.api_user_token !== null;
      this.loaderService.hide();
    });
  }


  generate() {
    this.loaderService.show();
    this.profileService.getRedirectLink(this.redirectString).subscribe({
      next: (response) => {
        this.loaderService.hide();
        console.log('Redirect Link:', response);
        this.redirectResponse = true;
        this.redirectParams = response.message;
      },
      error: (error) => {
        this.loaderService.hide();
        console.error('Error fetching redirect link:', error);
      }
    });
  }

  getCode() {
    if (!this.redirectResponse) {
      return '';
    } else {
      return `&data=${this.redirectParams}`;
    }
  }
  generateApi() {
    this.isGenerate = true;
    this.profileService.generateCode().subscribe();
  }

  getCodeApi() {
    if (!this.isGenerate || !this.api_user_token) {
      return '';
    } else {
      return `${this.generalService.api_user_token}`;
    }
  }
}
