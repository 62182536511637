import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { ColumnsEnum } from '../../enums/column.enum';
import { ReferralProgramService } from '../../service/referral-program.service';
import { rowModel } from '../../model/row.model';
import { Router } from '@angular/router';
import { filtersListsKey } from '../../../statistics/types/filters';
import { FiltersService } from '../../../statistics/services/filters.service';
import {GeneralService} from "../../../../../shared/states/general-state.service";

@Component({
  selector: 'app-referral-table',
  templateUrl: './referral-table.component.html',
  styleUrls: ['./referral-table.component.scss'],
})
export class ReferralTableComponent implements OnInit, AfterViewInit {
  limit = 0;
  numOfPages!: number;
  thereIsData!: boolean;
  isDesktop = true;

  @ViewChild('actions') actions!: TemplateRef<any>;

  constructor(
    public referralService: ReferralProgramService,
    private router: Router,
    private generalService: GeneralService,
    private cdr: ChangeDetectorRef,
    private filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    this.generalService.isDesktop$.subscribe((val) => {
      this.isDesktop = val;
    });
    this.referralService.rows$.subscribe((rows: rowModel[]) => {
      this.thereIsData = rows.length > 0;
      this.updateColumn();
    });
  }

  async ngAfterViewInit() {
    combineLatest([
      this.referralService.chosenLimit$,
      this.referralService.numOfPages$,
    ]).subscribe(([limit, numPages]) => {
      this.limit = limit;
      this.numOfPages = numPages;
      this.cdr.detectChanges();
    });
  }

  updateColumn() {
    this.referralService.updateColumnsData(
      [
        {
          prop: ColumnsEnum.ACTIONS,
          cellTemplate: this.actions,
          checkboxable: true,
          headerCheckboxable: true,
        },
      ],
      {}
    );
  }

  changePage(event: any) {
    this.referralService.currentPage = event.page;
    return true;
  }

  navigateToStatistics(row: any) {
    this.filtersService.navigateFromPage = true;
    this.filtersService.navigateFrom(
      [
        {
          key: row.id || '',
          value: row.name || '',
        },
      ],
      filtersListsKey.placement
    );
    this.router.navigate(['/statistics']);
  }
}
