<div class="container background popupWith">
  <div class="header">
    <span class="typography-title">Attention</span>
    <app-icon class="close" name="close" (click)="close()"></app-icon>
  </div>
  <div class="copy-frame-container">
    <span class="subtitle">Due to external reporting issues, some revenue related to the Content Locker product from July 29th is missing. The issue will be resolved soon.</span>

  </div>

</div>
