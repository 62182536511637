import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    MatTabsModule,
    RouterModule,
    MatBadgeModule,
    MatMenuModule
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
