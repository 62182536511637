import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Item } from 'src/app/shared/types/item';
import { PaymentDetailsModel } from '../../models/payment_details';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfileService } from '../../service/profile.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import {UserModel} from "../../../../common/login/store/models/user.model";


@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnChanges {
  @Input() paymentMethodList!: Item[];
  @Input() userDetails!: UserModel;
  @Input() paymentDetails!: PaymentDetailsModel | null;

  paymentMethodForm!: FormGroup;
  paymentDetailsDescription!: string;
  selectedKey: string = '';
  isDisable = true;
  user: any;
  // userDetails: any;

  constructor(
    private profileService: ProfileService,
    private loaderService: LoaderService,
  ) {}

  ngOnChanges(): void {
    this.intiForm();
    this.setPaymentDetailsDescription();
    this.selectedKey = this.paymentMethodList
      ? this.paymentMethodList.find(
          (item) =>
            item.value === this.paymentMethodForm.get('payment_method')?.value
        )?.key ?? ''
      : '';

    this.paymentMethodForm.valueChanges.subscribe(() => {
      if (this.paymentMethodForm.dirty) {
        if (this.paymentMethodForm.valid) {
          this.isDisable = false;
        } else if (this.paymentMethodForm.invalid) {
          this.isDisable = true;
        }
      }
    });
  }

  intiForm() {
    const initialSelectedValue = this.paymentMethodList
      ? this.paymentMethodList.find(
          (item) => Number(item.key) === this.paymentDetails?.payment_method
        )?.value
      : null;
    this.paymentMethodForm = new FormGroup({
      payment_method: new FormControl(
        initialSelectedValue || null,
        Validators.required
      ),
    });
  }

  onPaymentMethodChange() {
    this.paymentMethodForm.markAsDirty();
    this.changePaymentMethod();
  }

  changePaymentMethod() {
    this.selectedKey = this.paymentMethodList
      ? this.paymentMethodList.find(
      (item) =>
        item.value === this.paymentMethodForm.get('payment_method')?.value
    )?.key ?? ''
      : '';
  }

  checkDetails() {
    return !(this.userDetails.country && this.userDetails.city && this.userDetails.street && this.userDetails.zip_code);
  }

  setPaymentDetailsDescription() {
    this.paymentDetailsDescription =
      'profile.payment_method.payment_details_description.';

    switch (this.paymentDetails?.payment_period) {
      case 'bi weekly':
        this.paymentDetailsDescription =
          this.paymentDetailsDescription + 'bi_weekly';
        break;
      case 'makolet':
        this.paymentDetailsDescription =
          this.paymentDetailsDescription + 'makolet';
        break;
      case 'net 0':
        this.paymentDetailsDescription =
          this.paymentDetailsDescription + 'net_0';
        break;
      case 'net 15':
        this.paymentDetailsDescription =
          this.paymentDetailsDescription + 'net_15';
        break;
      case 'net 30':
      case undefined:
        this.paymentDetailsDescription =
          this.paymentDetailsDescription + 'net_30';
        break;
      default:
        break;
    }
  }

  submit(): void {
    const paymentValue = this.paymentMethodList.find(
      (item) =>
        item.value === this.paymentMethodForm.get('payment_method')?.value
    )?.key;
    if (this.paymentMethodForm.valid) {
      this.loaderService.show();
      const formData = {
        ...this.paymentMethodForm.value,
        payment_method: Number(paymentValue),
        beneficiary_name: this.paymentDetails?.beneficiary_name,
        beneficiary_address: this.paymentDetails?.beneficiary_address,
        payment_period: this.paymentDetails?.payment_period,
      };
      this.profileService.paymentMethodMail(formData).subscribe(() => {
        this.isDisable = true;
        this.loaderService.hide();
      });
    }
  }
}
