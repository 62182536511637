import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarObjectFailedPayment: Snackbar = {
  duration: 2000,
  message: 'payments.snackbar_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarVerificationSuccess: Snackbar = {
  duration: 2000,
  message: 'profile.verification.success',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const snackbarVerificationSFail: Snackbar = {
  duration: 2000,
  message: 'profile.verification.failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
