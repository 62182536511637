<div class="api">
  <span class="typography-heading3-bold title">
    {{ 'profile.advanced.main_title' | translate }}
  </span>
  <div class="api">
  <span class="typography-heading3-bold title">
    {{ 'profile.api_key.title' | translate }}
  </span>
    <div class="subtitle typography-heading4-thin">
      <div>{{ 'profile.api_key.subtitle1' | translate }}</div>
      <div>
        <div class="typography-headline2">
          {{ 'profile.api_key.subtitle2' | translate }}
          <a href="https://help.lootlabs.gg/en/category/api-documentation-1vutrk2/" class="here" target="_blank">
            {{ 'profile.api_key.here' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div class="generator">
      <!--    <div class="typography-headline2 generator-key">-->
      <!--      {{ 'profile.api_key.title' | translate }}:-->
      <!--    </div>-->
      <div class="copy">
        <app-button
          *ngIf="!isGenerate"
          color="primary"
          (buttonClick)="generateApi()"
        >
          {{ 'profile.api_key.btn' | translate }}
        </app-button
        >
        <app-copy-code
          [showCopy]="isGenerate"
          [linkText]="getCodeApi()"
        ></app-copy-code>
      </div>
    </div>
  </div>


  <div class="advanced">
  <span class="typography-heading3-bold title">
    {{ 'profile.advanced.title' | translate }}
  </span>
    <div class="subtitle typography-heading4-thin">
      <div>{{ 'profile.advanced.subtitle' | translate }}</div>
      <div>
        <div class="typography-headline2">
          {{ 'profile.advanced.subtitle2' | translate }}
          <a href="https://help.lootlabs.gg/en/article/lootlabs-redirect-api-9o0tky/" class="here" target="_blank">
            {{ 'profile.advanced.here' | translate }}
          </a>
        </div>
        <br>
        <div class="generate-red">
          <app-button
            color="primary"
            [disabled]="redirectString === ''"
            (buttonClick)="generate()">
            Create
          </app-button>
          <input placeholder="Place redirect url here" [(ngModel)]="redirectString" style="width: 20%;" type="text">
        </div>
        <br>
        <div class="copy">
          <app-copy-code
            *ngIf="redirectResponse"
            [showCopy]="redirectResponse"
            [linkText]="getCode()"
          ></app-copy-code>
        </div>
        <div *ngIf="redirectResponse" style="margin-top: 15px">
          <h6>Add this part after your original Lootlabs Link</h6>
          <div style="display: flex">
            <h6>Example : https://loot-link.com/s?xxxxx</h6>
            <h6 style="color: #06ee53">&data=yyyyy</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="advanced">
  <span class="typography-heading3-bold title">
    {{ 'profile.bot.title' | translate }}
  </span>
    <div class="subtitle typography-heading4-thin">
      <div>{{ 'profile.bot.subtitle' | translate }}</div>
      <div>
        <div class="typography-headline2">
          {{ 'profile.bot.subtitle2' | translate }}
          <a href="https://help.lootlabs.gg/en/article/how-to-use-the-lootlabs-discord-bot-1ibyolf/" class="here" target="_blank">
            {{ 'profile.advanced.here' | translate }}
          </a>
        </div>
        <br>
        <a class="blue-button" href="https://discord.com/oauth2/authorize?client_id=1204693168738803792&permissions=10240&scope=bot" target="_blank">
          {{ 'profile.bot.invite' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
