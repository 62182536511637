export class UserModel {
  id: number | null;
  first_name: string | null;
  last_name: string | null;
  street: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  phone: string | null;
  username: string | null;
  gender: string | null;
  password: string | null;
  company_name: string | null;
  profile_picture: string | null;
  account_type: number | null;
  agreement_id: String | null;
  group_id: number | null;
  mode: number | null;
  credit_card: string | null;
  credit_card_number: string | null;
  owner_name: string | null;
  safety_number: string | null;
  expiration_date: string | null;
  country: string | null;
  billing_street: string | null;
  billing_city: string | null;
  billing_zip_code: string | null;
  register_time: string | null;
  source_id: string | null;
  account_manager_id: number | null;
  signup_ip: number | null;
  signup_country: string | null;
  source_campaign: string | null;
  verify: number | null;
  accepted_disclaimer: string | null;
  max_tags_per_site: number | null;
  max_sites: number | null;
  read_terms: number | null;
  bitcoin_allowed: number | null;
  israeli: string | null;
  cost_by_external: number | null;
  cost_by_external_from_date: string | null;
  outsource: string | null;
  brand: string | null;
  user_option: number | null;
  telegram: string | null;
  contact_source: string | null;
  refferer: string | null;
  gclid: string | null;
  signup_contact_type: string | null;
  signup_contact_id: string | null;
  api_user_token: string | null;
  crypto_allowed_status: string | null;
  verification_image: string | null;

  constructor() {
    this.id = null;
    this.first_name = '';
    this.last_name = '';
    this.username = '';
    this.street = null;
    this.country = null;
    this.city = null;
    this.state = null;
    this.zip_code = null;
    this.company_name = null;
    this.profile_picture = null;
    this.account_manager_id = null;
    this.signup_ip = null;
    this.signup_country = null;
    this.accepted_disclaimer = null;
    this.max_tags_per_site = null;
    this.max_sites = null;
    this.bitcoin_allowed = null;
    this.signup_contact_id = null;
    this.signup_contact_type = null;
    this.safety_number = null;
    this.phone = null;
    this.gender = null;
    this.password = null;
    this.account_type = null;
    this.agreement_id = null;
    this.group_id = null;
    this.mode = null;
    this.credit_card = null;
    this.credit_card_number = null;
    this.owner_name = null;
    this.expiration_date = null;
    this.billing_street = null;
    this.billing_city = null;
    this.billing_zip_code = null;
    this.register_time = null;
    this.source_id = null;
    this.source_campaign = null;
    this.verify = null;
    this.read_terms = null;
    this.israeli = null;
    this.cost_by_external = null;
    this.cost_by_external_from_date = null;
    this.outsource = null;
    this.brand = null;
    this.user_option = null;
    this.telegram = null;
    this.contact_source = null;
    this.refferer = null;
    this.gclid = null;
    this.api_user_token = null;
    this.crypto_allowed_status = null;
    this.verification_image = null;
  }
}
