<app-card>
<div class="referral">
  <div class="header">
    <div class="title typography-heading4-bold">
      {{ 'referral_program.title' | translate }}
    </div>
    <div class="subtitle typography-body4-thin">
      {{ 'referral_program.subtitle' | translate }}
    </div>
  </div>
  <div class="commision-container" [ngClass]="{ 'commision-container-mobile': !isDesktop }">
    <div class="left-container">
      <div class="title-referral typography-heading2">
        {{ 'referral_program.commission.title' | translate }}
      </div>
      <div class="title-referral typography-headline2 subtitle">
        {{ 'referral_program.commission.subtitle' | translate }}
      </div>
    </div>
    <div class="right-container">
      <div class="title-referral title typography-footnote1">
        {{ 'referral_program.commission.unique' | translate }}
      </div>
      <div class="copy">
        <app-button
          *ngIf="!isGenerate"
          color="secondary"
          (buttonClick)="generate()"
        >
          {{ 'referral_program.commission.generate' | translate }}</app-button
        >
        <app-copy-code
          [showCopy]="isGenerate"
          [linkText]="getCode()"
        ></app-copy-code>
      </div>
    </div>
  </div>

  <div class="table-header">
    <div class="title typography-heading4-bold">
      {{ 'referral_program.your_referrals' | translate }}
    </div>
  </div>
  <app-referral-table></app-referral-table>
</div>
</app-card>
