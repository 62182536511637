<div class="form-container" [formGroup]="paymentMethodForm">
  <div class="header">
    <span class="title typography-heading3-thin">
      {{
        paymentMethodForm.get('payment_method')?.value === 'Bitcoin'
          ? 'Crypto'
          : paymentMethodForm.get('payment_method')?.value
      }}
    </span>
  </div>
  <div class="container typography-footnote1" *ngIf="selectedKey == '2'">
    <div class="beneficiary-info">
      <span class="typography-heading4-thin">{{
        'profile.payment_method.form.beneficiary_info_title' | translate
      }}</span>
      <div class="row">
        <span class="field typography-footnote1">{{
          'profile.payment_method.form.beneficiary_name' | translate
        }}</span>
        <span class="typography-footnote2-thin">{{
          paymentDetails?.beneficiary_name
        }}</span>
      </div>
      <div class="row">
        <span class="field typography-footnote1">{{
          'profile.payment_method.form.beneficiary_address' | translate
        }}</span>
        <span class="typography-footnote2-thin">{{
          paymentDetails?.beneficiary_address
        }}</span>
      </div>
    </div>
    <div class="bank-account">
      <span class="typography-heading4-thin">{{
        'profile.payment_method.form.bank_account_title' | translate
      }}</span>
      <div class="form-content">
        <div class="col">
          <label for="bank_name">{{
            'profile.payment_method.form.bank_name' | translate
          }}</label>
          <app-input
            [invalid]="
              paymentMethodForm.get('bank_name')?.hasError('required') &&
              !paymentMethodForm.get('bank_name')?.pristine
            "
            type="text"
            formControlName="bank_name"
            [placeholder]="
              'profile.payment_method.form.bank_name_placeholder' | translate
            "
          ></app-input>
        </div>
        <div class="col">
          <label for="bank_address">{{
            'profile.payment_method.form.bank_address' | translate
          }}</label>
          <app-input
            [invalid]="
              paymentMethodForm.get('bank_address')?.hasError('required') &&
              !paymentMethodForm.get('bank_address')?.pristine
            "
            type="text"
            formControlName="bank_address"
            [placeholder]="
              'profile.payment_method.form.bank_address_placeholder' | translate
            "
          ></app-input>
        </div>
      </div>
      <div class="form-content">
        <div class="col">
          <label for="bank_country">{{
            'profile.payment_method.form.bank_country' | translate
          }}</label>
          <ng-select
            class="dropdown typography-body3-thin"
            [multiple]="false"
            [items]="paymentMethodFormService.countries$ | async"
            bindLabel="value"
            bindValue="key"
            formControlName="bank_country"
            [ngClass]="{
              'has-value': paymentMethodForm.get('bank_country') !== null
            }"
            [class.error]="
              paymentMethodForm.get('bank_country')?.hasError('required')
            "
            [placeholder]="
              'profile.payment_method.form.bank_country_placeholder' | translate
            "
          >
          </ng-select>
        </div>
        <div class="col">
          <label for="bank_city">{{
            'profile.payment_method.form.bank_city' | translate
          }}</label>
          <ng-select
            class="dropdown typography-body3-thin"
            [multiple]="false"
            [items]="paymentMethodFormService.cities$ | async"
            formControlName="bank_city"
            bindLabel="value"
            bindValue="key"
            [ngClass]="{
              'has-value': paymentMethodForm.get('bank_city') !== null
            }"
            [class.error]="
              paymentMethodForm.get('bank_city')?.hasError('required')
            "
            [placeholder]="
              'profile.payment_method.form.bank_city_placeholder' | translate
            "
            [readonly]="disableCities"
          ></ng-select>
        </div>
      </div>

      <div class="form-content">
        <div class="col">
          <label for="account_number">{{
            'profile.payment_method.form.account_number' | translate
          }}</label>
          <app-input
            type="text"
            formControlName="account_number"
            [isNumericInputAllowed]="true"
            [placeholder]="
              'profile.payment_method.form.account_number_placeholder'
                | translate
            "
          ></app-input>
        </div>
        <div class="col">
          <label for="iban_number">{{
            'profile.payment_method.form.iban' | translate
          }}</label>
          <app-input
            type="text"
            formControlName="iban_number"
            [placeholder]="
              'profile.payment_method.form.iban_placeholder' | translate
            "
          ></app-input>
        </div>
      </div>

      <div class="form-content">
        <div class="col">
          <label for="swift_code">{{
            'profile.payment_method.form.swift' | translate
          }}</label>
          <app-input
            [invalid]="
              paymentMethodForm.get('swift_code')?.hasError('required') &&
              !paymentMethodForm.get('swift_code')?.pristine
            "
            type="text"
            formControlName="swift_code"
            [placeholder]="
              'profile.payment_method.form.swift_placeholder' | translate
            "
          ></app-input>
        </div>
        <div class="col"></div>
      </div>

      <div>
        <label for="comment">{{
          'profile.payment_method.form.comment' | translate
        }}</label>
        <app-input
          type="text"
          formControlName="comment"
          [placeholder]="
            'profile.payment_method.form.comment_placeholder' | translate
          "
        ></app-input>
      </div>
    </div>
  </div>
  <div
    class="form-content regular typography-footnote1"
    *ngIf="selectedKey != '2' && selectedKey !== '4' && selectedKey !== '10'"
  >
    <div class="col">
      <label for="payment_email">{{
        isWallet()
          ? ('profile.payment_method.form.wallet_title' | translate)
          : ('profile.payment_method.form.email_title' | translate)
      }}</label>
      <app-input
        [invalid]="
          paymentMethodForm.get('payment_email')?.hasError('required') &&
          !paymentMethodForm.get('payment_email')?.pristine
        "
        type="text"
        formControlName="payment_email"
        [placeholder]="
          isWallet()
            ? ('profile.payment_method.form.wallet_placeholder' | translate)
            : ('profile.payment_method.form.email_placeholder' | translate)
        "
      ></app-input>
    </div>
    <div class="col"></div>
  </div>
  <div
    class="form-content regular typography-footnote1"
    *ngIf="selectedKey == '4' || selectedKey == '10'"
  >
    <div class="col">
      <label *ngIf="checkCrypto()"
             for="payment_email">{{
          isWallet()
            ? ("profile.payment_method.form.wallet_title" | translate)
            : ("profile.payment_method.form.email_title" | translate)
        }}</label>
      <app-input
        *ngIf="checkCrypto()"
        [invalid]="
          paymentMethodForm.get('payment_email')?.hasError('required') &&
          !paymentMethodForm.get('payment_email')?.pristine
        "
        type="text"
        formControlName="payment_email"
        [placeholder]="
          isWallet()
            ? ('profile.payment_method.form.wallet_placeholder' | translate)
            : ('profile.payment_method.form.email_placeholder' | translate)
        "
      ></app-input>
      <!--      LINK TO DETAILS-->
      <h4 class="typography-footnote1" style="margin: 0" *ngIf="!checkCrypto()">Identity verification is required under Lootlabs's updated KYC policy for crypto payments. </h4>
      <h4 class="typography-footnote1" style="margin-top: 10px; margin-bottom: 10px" *ngIf="this.userDetails?.crypto_allowed_status ===  'approved'">  <span class="checkmark">✓</span>Your identification document has been reviewed and approved. Please enter your crypto wallet address.</h4>
      <h4 class="typography-footnote1" style="margin-top: 10px; margin-bottom: 10px" *ngIf="this.userDetails?.crypto_allowed_status ===  'pending' && this.userDetails?.verification_image">  <span class="pending-icon">?</span>Your identification document is pending approval. Our support team will review it shortly and will email you once it is approved or rejected.</h4>
      <h4 class="typography-footnote1" style="margin-top: 10px; margin-bottom: 10px" *ngIf="this.userDetails?.crypto_allowed_status ===  'rejected'">  <span class="x-mark">✗</span>Your identification document has been rejected. <br> Please ensure that the document matches the details you entered in the personal details section.</h4>
      <label class="typography-footnote1" *ngIf="!checkCrypto()" for="verification_image">Please upload an image of your ID (passport, identification document, driver's license, or similar) that matches your updated personal details to verify this payment method. (Max 1MB)</label>
      <app-upload-image
        *ngIf="!checkCrypto()"
        type="image"
        formControlName="verification_image"
        [displayLoader]="true"
      ></app-upload-image>
    </div>
  </div>
  <h5 class="typography-footnote1" style="font-size: 12px">Please Note: You cannot edit your payment method during the payment cycle (the first five days of each month).<br>Please ensure your payment details are updated before the payment cycle begins.</h5>
</div>
