<div class="">
    <span class="subtitle typography-heading4-thin"  *ngIf="checkDetails()">
     Before you can add your payment information, please make sure to fill in your Personal Settings + Address Details.
    </span>
</div>
<div class="payment-container" [formGroup]="paymentMethodForm" [ngClass]="{ 'block-action': checkDetails() }">
  <span class="title typography-heading3-bold">{{
      'profile.payment_method.title' | translate
    }}</span>
  <div
    class="payment-method-container"
    [ngClass]="{ padding: selectedKey == '' }"
  >
    <span class="subtitle typography-heading4-thin">{{
        'profile.payment_method.payment_method' | translate
      }}</span>
    <ng-select
      class="payment-dropdown typography-headline2"
      [searchable]="false"
      [clearable]="false"
      [items]="paymentMethodList"
      bindLabel="value"
      bindValue="value"
      (change)="onPaymentMethodChange()"
      formControlName="payment_method"
      [placeholder]="'profile.payment_method.placeholder' | translate"
      [class.error]="paymentMethodForm.get('payment_method')?.invalid"
      [ngClass]="{
        'has-value': paymentMethodForm.get('payment_method')?.value !== null
      }"
    >
      <ng-template ng-option-tmp let-item="item">
        {{ item.value === 'Bitcoin' ? 'Crypto' : item.value }}
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        {{ item.value === 'Bitcoin' ? 'Crypto' : item.value }}
      </ng-template>
    </ng-select>
  </div>
  <app-payment-method-form
    *ngIf="selectedKey"
    [paymentMethodForm]="paymentMethodForm"
    [selectedKey]="selectedKey"
    [paymentMethodList]="paymentMethodList"
    [paymentDetails]="paymentDetails"
  ></app-payment-method-form>
  <div class="payment-details-container">
    <span class="subtitle typography-heading4-thin">{{
        'profile.payment_method.payment_details' | translate
      }}</span>
    <span class="description typography-headline2">{{
        paymentDetailsDescription | translate
      }}</span>
    <div class="card">
      <app-icon name="lamp"></app-icon>
      <div class="header">
        <span class="typography-body3-thin">{{
            'profile.payment_method.example_title' | translate
          }}</span>
        <span class="typography-headline2">{{
            'profile.payment_method.example_description' | translate
          }}</span>
        <a
          target="_blank"
          href="https://help.lootlabs.gg/en/article/getting-paid-payement-information-1f5usut/"
          class="link"
        >
          <span class="typography-headline2">{{
              'profile.payment_method.example_link' | translate
            }}</span>
          <app-icon name="link"></app-icon>
        </a>
      </div>
    </div>
  </div>
  <app-button class="button" (buttonClick)="submit()" [disabled]="isDisable">{{
      'profile.payment_method.submit' | translate
    }}
  </app-button>
</div>
