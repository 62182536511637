import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { linkOptions } from '../../constants/link-option';
import {convertFromValueToKey, decrement, increment} from 'src/app/shared/helper/helper';
import { youtubeValidator } from 'src/app/shared/validators/validatiors';

@Component({
  selector: 'app-set-up-screen',
  templateUrl: './set-up-screen.component.html',
  styleUrls: ['./set-up-screen.component.scss'],
})
export class SetUpScreenComponent implements OnInit {
  @Input() setUpForm!: FormGroup;
  @Input() isSingle: boolean = true;
  @Input() background = true;
  @Output() continue = new EventEmitter<any>();
  linkOptions = linkOptions;
  private tier_id: any;

  constructor(
    public generalService: GeneralService,
    private cdr: ChangeDetectorRef
  ) {}
  themeList!: { key: string; value: string }[];
  tiersList!: { key: string; value: string }[];
  socialList!: { key: string; value: string }[];
  numOfTasksList = [1, 2, 3, 4, 5];
  isSubscription = false;
  ngOnInit(): void {
    this.generalService.themes$.subscribe(() => {
      this.themeList = this.generalService.getThemeAsArray();
    });

    this.generalService.ad_tier$.subscribe(() => {
      this.tiersList = this.generalService.getTierAsArray();
    });

    this.setUpForm.get('link_option')?.valueChanges.subscribe((val: any) => {
      this.checkBackgroundValidation(val);
      this.setUpForm.get('background')?.reset();
    });

    this.isSubscription =
      this.setUpForm.get('social_channel')?.value &&
      this.setUpForm.get('social_channel')?.value !== 'None';

    this.generalService.social_channels$.subscribe(() => {
      this.socialList = this.generalService.getSocialAsArray();
    });
  }

  checkBackgroundValidation(val: string) {
    if (val === linkOptions[0].key) {
      this.setUpForm
        .get('background')
        ?.removeValidators(this.applyValidators());
      this.setUpForm.get('background')?.updateValueAndValidity();
    } else {
      this.setUpForm.get('background')?.setValidators(this.applyValidators());
      this.setUpForm.get('background')?.updateValueAndValidity();
    }
  }

  onTierChange(event: any) {
    this.tier_id = convertFromValueToKey(
      this.generalService.getTierAsArray(),
      event.value
    )
    this.updateChart(this.tier_id);
  }

  updateChart(tierId: number): void {
    const profitBar = document.getElementById('profit-bar');
    const uxBar = document.getElementById('ux-bar');

    let profitWidth = '0%';
    let uxWidth = '0%';

    switch (tierId) {
      case 1:
        profitWidth = '25%';
        uxWidth = '100%';
        break;
      case 2:
        profitWidth = '50%';
        uxWidth = '75%';
        break;
      case 3:
        profitWidth = '75%';
        uxWidth = '50%';
        break;
      case 4:
        profitWidth = '100%';
        uxWidth = '25%';
        break;
    }

    if (profitBar) {
      profitBar.style.width = profitWidth;
    }
    if (uxBar) {
      uxBar.style.width = uxWidth;
    }
  }

  applyValidators() {
    const backgroundControl = this.setUpForm.get('background');
    const linkOptionControl = this.setUpForm.get('link_option');
    return youtubeValidator({
      control: backgroundControl,
      additionalControls: linkOptionControl,
    });
  }

  isChanged(event: boolean) {
    this.isSubscription = event;
    this.setUpForm.get('social_channel')?.reset();
    if (event) {
      this.setUpForm
        .get('social_channel')
        ?.setValidators([Validators.required]);
      this.setUpForm.updateValueAndValidity();
    } else {
      this.setUpForm
        .get('social_channel')
        ?.removeValidators([Validators.required]);
      this.setUpForm.get('social_channel')?.updateValueAndValidity();
    }
    this.cdr.detectChanges();
  }

  next() {
    this.continue.emit();
  }

  increment(): void {
    const updatedValue = increment(
      this.setUpForm.get('number_of_tasks')?.value,
      5
    );
    this.setUpForm.get('number_of_tasks')?.setValue(updatedValue);
  }

  decrement(): void {
    const updatedValue = decrement(
      this.setUpForm.get('number_of_tasks')?.value,
      0
    );

    this.setUpForm.get('number_of_tasks')?.setValue(updatedValue);
  }
}
