<div class="">
    <span class="subtitle typography-heading4-thin"  *ngIf="checkDetails()">
     Before you can add your payment information, please make sure to fill in your Personal Settings + Address Details.
    </span>
</div>
<div class="payment-container" [formGroup]="paymentMethodForm" [ngClass]="{ 'block-action': checkDetails() }">
  <span class="title typography-heading3-bold">{{
      'profile.payment_method.title' | translate
    }}</span>
  <div
    class="payment-method-container"
    [ngClass]="{ padding: selectedKey == '' }"
  >
    <span class="subtitle typography-heading4-thin">{{
        'profile.payment_method.payment_method' | translate
      }}</span>
    <ng-select
      class="payment-dropdown typography-headline2"
      [searchable]="false"
      [clearable]="false"
      [items]="paymentMethodList"
      bindLabel="value"
      bindValue="value"
      (change)="onPaymentMethodChange()"
      formControlName="payment_method"
      [placeholder]="'profile.payment_method.placeholder' | translate"
      [class.error]="paymentMethodForm.get('payment_method')?.invalid"
      [ngClass]="{
        'has-value': paymentMethodForm.get('payment_method')?.value !== null
      }"
    >
      <ng-template ng-option-tmp let-item="item">
        {{ item.value === 'Bitcoin' ? 'Crypto' : item.value }}
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        {{ item.value === 'Bitcoin' ? 'Crypto' : item.value }}
      </ng-template>
    </ng-select>
  </div>
  <div style="color: cornflowerblue" class="typography-body3-thin" *ngIf="payLink">Please verify your Payoneer account <a style="color: white" href="{{payLink}}">HERE</a></div>
  <app-payment-method-form
    *ngIf="selectedKey"
    [paymentMethodForm]="paymentMethodForm"
    [selectedKey]="selectedKey"
    [paymentMethodList]="paymentMethodList"
    [paymentDetails]="paymentDetails"
    [userDetails]="userDetails"
  ></app-payment-method-form>
  <div class="payment-details-container">
    <span *ngIf="this.paymentDetails?.payment_period" class="subtitle typography-heading4-thin">{{
        'profile.payment_method.payment_details' | translate
      }}</span>
    <span *ngIf="this.paymentDetails?.payment_period" class="description typography-headline2">{{
        paymentDetailsDescription | translate
      }}</span>
    <div class="card" style="height: 250px;overflow: auto">
      <app-icon name="lamp"></app-icon>
      <div class="header">
        <span class="typography-body3-thin">{{
            "profile.payment_method.example_title" | translate
          }}</span>
        <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; overflow: auto">
          <div style="padding: 10px 0; margin-bottom: 15px;">
            <h2
              style="font-size: 15px; font-weight: 600; color: #c1dcf4;padding-bottom: 10px; border-bottom: 1px solid #e1e1e1; margin: 0">
              Do you transfer charge fees?</h2>

            <ul style="list-style-type: none; padding: 0; margin: 0;">
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white; color: white;">
                <span style="font-weight: 500; color: #3498db;">Paypal:</span> No fees.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;color: white;">
                <span style="font-weight: 500; color: #3498db;">Payoneer:</span> No fees; fees depend on your Payoneer
                account type.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Bitcoin:</span> 3% fee.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">USDT (ERC20):</span> 3% fee.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Paxum:</span> No fees.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">WebMoney:</span> 2% fee.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Wire Transfer (bank):</span> Fees vary based on your
                bank's commission.
              </li>
              <li style="padding: 8px 0; color: #3498db">
                <span style="font-weight: 500; color: #3498db;">Capitalist:</span> No fees.
              </li>
            </ul>
          </div>

          <div style="padding: 10px 0;">
            <h2
              style="font-size: 15px; font-weight: 600; color: #c1dcf4; margin-bottom: 16px; padding-bottom: 10px; border-bottom: 1px solid #e1e1e1;">
              What is your payment policy?</h2>

            <ul style="list-style-type: none; padding: 0; margin: 0;">
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Minimum payout for Paypal:</span> No minimum.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Minimum payout for Payoneer:</span> There is no set
                minimum on our end, The minimum amount depends on your Payoneer account.
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Minimum payout for Bitcoin:</span> $1 minimum.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Minimum payout for USDT (ERC20):</span> No minimum.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Minimum payout for Paxum:</span> No minimum.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Minimum payout for WebMoney:</span> No minimum.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Minimum payout for Wire Transfer (Bank):</span> $800
                minimum.
              </li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">
                <span style="font-weight: 500; color: #3498db;">Minimum payout for Capitalist:</span> No minimum.
              </li>
            </ul>

            <div style="margin-top: 20px; padding-top: 15px; border-top: 1px dashed #ccc;">

              <p style="margin: 12px 0; color: white;">If payment details are not updated before the end of the month, the payment
                will be delayed.</p>

              <p style="margin: 12px 0;color: white;">If your account balance does not meet the minimum payout, payment will not be
                processed.</p>

              <p style="margin: 12px 0;color: white;">
                Once you reach the minimum payout for your selected payment method, submit a payout request by
                contacting
                <a href="mailto:publishers@ad-maven.com"
                   style="color: #2980b9; text-decoration: none; font-weight: 500;">publishers@ad-maven.com</a>.
              </p>

              <p style="margin: 12px 0;color: white;">📌 Include the following details in your request:</p>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">Account email</li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">Website details</li>
              <li style="padding: 8px 0; border-bottom: 1px solid #eee; color: white;">Exact months for which you are requesting
                payment
              </li>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="typography-body3-thin" *ngIf="emptyValue && !firstFiveDays">
    <input type="checkbox" (change)="toggleChange()">
    <span>{{ "profile.payment_method.notice" | translate }} <a style="color: dodgerblue" target="_blank"
                                                               href="https://lootlabs.gg/terms-of-use">{{ "profile.payment_method.terms" | translate }}</a></span>
  </div>
  <div class="typography-body3-thin" *ngIf="firstFiveDays">
    You cannot edit your payment method during the payment cycle (the first five days of each month)
  </div>
  <app-button class="button" (buttonClick)="submit()" [disabled]="isDisable || firstFiveDays || isToggled">{{
      "profile.payment_method.submit" | translate
    }}
  </app-button>
</div>
