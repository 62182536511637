<div class="set-up-container" [formGroup]="setUpForm">
  <div *ngIf="isSingle" class="column">
    <div class="header">
      <div class="title typography-footnote2-bold">
        {{ 'single.set_up.title_page' | translate }}
      </div>
      <div class="subtitle typography-footnote2-thin">
        {{ 'single.set_up.subtitle_page' | translate }}
      </div>
    </div>
    <app-input
      class="input-field"
      formControlName="title"
      [placeholder]="'single.set_up.placeholder_page' | translate"
      [invalid]="
        (setUpForm.get('title')?.hasError('required') &&
          !setUpForm.get('title')?.pristine) ||
        (setUpForm.get('title')?.hasError('minlength') &&
          !setUpForm.get('title')?.pristine)
      "
      [maxLength]="30"
      [textCounter]="true"
    >
    </app-input>
  </div>
  <div class="column">
    <div class="header">
      <div class="title typography-footnote2-bold">
        {{ 'single.set_up.title_theme' | translate }}
      </div>
      <div class="subtitle typography-footnote2-thin">
        {{ 'single.set_up.subtitle_theme' | translate }}
      </div>
    </div>

    <ng-select
      class="theme typography-headline2"
      [multiple]="false"
      [items]="themeList"
      [searchable]="false"
      bindLabel="value"
      bindValue="value"
      formControlName="theme_id"
      [placeholder]="'single.set_up.placeholder_theme' | translate"
      class="dropdown"
      [clearable]="false"
      [ngClass]="{
        'has-value': setUpForm.get('theme_id') !== null
      }"
      [class.error]="setUpForm.get('theme_id')?.hasError('required')"
    ></ng-select>
  </div>

  <div *ngIf="background">
    <div class="title typography-footnote2-bold">
      {{ 'single.step_1.upload_file_title' | translate }}
    </div>
    <div class="subtitle typography-footnote2-thin">
      {{ 'single.step_1.upload_file_subtitle' | translate }}
    </div>
    <div class="form-container">
      <div class="form-content">
        <div class="col">
          <app-radio-group
            class="radio-buttons-group"
            [groupName]="'link_option'"
            formControlName="link_option"
          >
            <app-radio-button
              class="radio-button"
              [groupName]="'link_option'"
              value="{{ linkOptions[0].key }}"
            >
              <ng-template let-checked="checked" let-disabled="disabled">
                <div [class.checked]="checked" [class.disabled]="disabled">
                  <div class="label">
                    <app-radio-circle class="circle" [checked]="checked">
                    </app-radio-circle>
                    <span class="typography-footnote2-thin">
                      {{ linkOptions[0].title | translate }}</span
                    >
                  </div>
                </div>
              </ng-template>
            </app-radio-button>

            <div
              *ngIf="setUpForm.get('link_option')?.value === linkOptions[0].key"
            >
              <app-upload-image
                [preview]="setUpForm.get('background')?.value"
                formControlName="background"
              >
              </app-upload-image>
            </div>

            <app-radio-button
              class="radio-button"
              [groupName]="'link_option'"
              value="{{ linkOptions[1].key }}"
            >
              <ng-template let-checked="checked" let-disabled="disabled">
                <div [class.checked]="checked" [class.disabled]="disabled">
                  <div class="label">
                    <app-radio-circle class="circle" [checked]="checked">
                    </app-radio-circle>
                    <span class="typography-footnote2-thin">
                      {{ linkOptions[1].title | translate }}</span
                    >
                  </div>
                </div>
              </ng-template>
            </app-radio-button>
            <div
              *ngIf="setUpForm.get('link_option')?.value === linkOptions[1].key"
            >
              <app-input
                class="name"
                formControlName="background"
                [placeholder]="'single.step_1.youtube_placeholder' | translate"
                [invalid]="
                  (setUpForm.get('background')?.invalid &&
                    !setUpForm.get('background')?.pristine) ||
                  (setUpForm.get('background')?.hasError('required') &&
                    !setUpForm.get('background')?.pristine)
                "
              >
              </app-input>
            </div>

            <app-radio-button
              class="radio-button"
              [groupName]="'link_option'"
              value="{{ linkOptions[2].key }}"
            >
              <ng-template let-checked="checked" let-disabled="disabled">
                <div [class.checked]="checked" [class.disabled]="disabled">
                  <div class="label">
                    <app-radio-circle class="circle" [checked]="checked">
                    </app-radio-circle>
                    <span class="typography-footnote2-thin">
                      {{ linkOptions[2].title | translate }}</span
                    >
                  </div>
                </div>
              </ng-template>
            </app-radio-button>
          </app-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div class="column">
    <div class="header">
      <div class="title typography-footnote2-bold">
        {{ 'single.set_up.title_tasks' | translate }}
      </div>
      <div class="subtitle typography-footnote2-thin">
        {{ 'single.set_up.subtitle_tasks' | translate }}
      </div>
    </div>
    <div class="line">
      <div class="column tiers">
        <div class="subtitle typography-footnote2-thin">
          {{ 'single.set_up.label_tasks' | translate }}
        </div>
        <ng-select
          class="dropdown dropdown-tiers typography-headline2"
          [multiple]="false"
          [items]="tiersList.reverse()"
          bindLabel="value"
          bindValue="value"
          formControlName="tier_id"
          [placeholder]="'single.set_up.placeholder_tier' | translate"
          [clearable]="false"
          [searchable]="false"
          [ngClass]="{
            'has-value': setUpForm.get('tier_id') !== null
          }"
          [class.error]="setUpForm.get('tier_id')?.hasError('required')"
          (change)="onTierChange($event)"
        ></ng-select>
        <div class="chart-container">
          <div class="chart-row">
            <div class="chart-label">Revenue Potential</div>
            <div class="chart-bar-container">
              <div id="profit-bar" class="chart-bar"></div>
            </div>
          </div>
          <div class="chart-row">
            <div class="chart-label">User Experience</div>
            <div class="chart-bar-container">
              <div id="ux-bar" class="chart-bar"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="subtitle typography-footnote2-thin">
          {{ 'single.set_up.tasks_number' | translate }}
        </div>
        <div class="counter">
          <app-button size="sm" (buttonClick)="decrement()">-</app-button>
          <span class="typography-footnote2-thin">{{
            setUpForm.get('number_of_tasks')?.value
          }}</span>
          <app-button size="sm" (buttonClick)="increment()">+</app-button>
        </div>
      </div>
    </div>
    <div *ngIf="isSingle" class="column subscription">
<!--      <app-checkbox-->
<!--        [checked]="isSubscription"-->
<!--        (checkboxChanged)="isChanged($event)"-->
<!--        [label]="'single.set_up.add_subscription_placeholder' | translate"-->
<!--        [tooltip]="'Lets you add a subscription task instead of one of the ad tasks, help you grow your social channels at the cost of one of your ads.'"-->
<!--      ></app-checkbox>-->
      <ng-select
        *ngIf="isSubscription"
        class="dropdown typography-headline2"
        [multiple]="false"
        [items]="socialList"
        bindLabel="value"
        bindValue="key"
        formControlName="social_channel"
        class="dropdown"
        [clearable]="false"
        [searchable]="false"
        [ngClass]="{
          'has-value': setUpForm.get('social_channel') !== null
        }"
        [placeholder]="'single.set_up.add_subscription_placeholder' | translate"
        [class.error]="setUpForm.get('social_channel')?.hasError('required')"
      ></ng-select>
    </div>
    <app-button
      [disabled]="
        setUpForm.invalid || setUpForm.get('number_of_tasks')?.value == 0
      "
      (buttonClick)="next()"
      class="next"
    >
      {{
        isSingle
          ? ('single.set_up.btn' | translate)
          : ('single.edit_multiple.btn' | translate)
      }}
    </app-button>
  </div>
</div>
