export const environment = {
  production: false,
  apiUrl: 'https://creators.lootlabs.gg/api',
  actions: {
    enums: '/enums',
    user: '/user',
    creator: '/creator',
    resetPasswordEmail: '/reset_password_email?email=',
    getPaymentMethod: '/user_payment_method',
    paymentMethodEmail: '/save_payment_changes_email',
    site: '/site',
    tag: '/tag',
    get_placement_code: '/get_placement_code',
    content_locker: '/content_locker',
    revenue: '/revenue',
    download_content_locker_file: '/download_bulk_links_example',
    statisticsFilters: '/statistics_filters',
    userColumns: '/user_columns',
    reports: '/reports',
    notifications: '/notifications',
    account_manager: '/account_manager',
    api_token: '/api_token',
    payment: '/payments_history',
    categories: '/categories',
    url_encryptor: '/url_encryptor',
    kill_switch_status: '/production_consts?id=LOOTLABS_PANEL_KILL_SWITCH',
  },
  links: {
    signUpLink: 'https://lootlabs.gg/#formStart',
    termsOfUseLink:
      'https://lootlabs.gg/terms',
    privacyPolicyLink:
      'https://lootlabs.gg/privacy',
  },
  recaptcha: {
    siteKey: '6Le2W5YpAAAAAK-yu-imdDkUfdJ3Jrb6B-JI7xzH',
  },
  params: {
    referral: '?referral=1',
    notificationsSeen: '?seen=1',
    notificationId: '?notification_id=',
    notificationClicked: '?clicked=1',
  },
};
