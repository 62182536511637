<div *ngIf="creatorDetails" [formGroup]="form" class="user-details">
  <div class="avatar">
    <app-upload-image
      [profile]="true"
      preview="{{ form.get('creator_picture')?.value }} "
      formControlName="creator_picture"
    >
    </app-upload-image>
  <h5 style="margin-top: 5px;width: 200px;" *ngIf="form.get('creator_picture')?.value === './assets/svg/placeholder_image.svg'">You must upload an image</h5>
  </div>
  <div class="details">
    <div class="subtitle typography-heading3-thin">
      {{ creatorDetails.creator_name }}
    </div>
    <div class="email typography-body3-thin">
      {{ creatorDetails.publisher_username }}
    </div>
  </div>
</div>
