import { Inject, Injectable } from '@angular/core';
import { Filter, FilterList, filtersListsKey } from '../types/filters';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG } from 'src/app';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Item } from 'src/app/shared/types/item';
import { first, map } from 'rxjs/operators';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { StatisticsService } from './statistics.service';
import { loadCountries, objectToArray } from 'src/app/shared/helper/helper';
import { GeneralFacade } from 'src/app/shared/states/general-state.facade';
import {
  snackbarObjectFailedColumns,
  snackbarObjectFailedFilters,
} from '../snackbar/snackbar';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private readonly apiUrl: string;
  private hasCountry = new BehaviorSubject<boolean>(false);
  public hasCountry$ = this.hasCountry.asObservable();
  numOfTotalCountries = 0;
  navigateFromPage = false;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private generalService: GeneralService,
    private loginService: LoginService,
    private statisticsService: StatisticsService,
    private facade: GeneralFacade,
    private loaderService: LoaderService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  public adFormatsIdTags = {}

  public placementList = new BehaviorSubject<FilterList<any>>({
    title: 'statistics.filters.placement',
    key: filtersListsKey.tags,
    data: [],
    multiple_search: true,
    groupBy: 'selectedAllGroup1',
    placeholder: 'statistics.filters.placement_place_holder',
  });

  public placementReferralList = new BehaviorSubject<FilterList<any>>({
    title: 'statistics.filters.placement',
    key: filtersListsKey.tags,
    data: [],
    multiple_search: true,
    groupBy: 'selectedAllGroup1',
    placeholder: 'statistics.filters.placement_place_holder',
  });

  public linkList = new BehaviorSubject<FilterList<any>>({
    title: 'statistics.filters.link',
    key: filtersListsKey.links,
    data: [],
    multiple_search: true,
    groupBy: 'selectedAllGroup1',
    placeholder: 'statistics.filters.link_place_holder',
  });

  public adFormatsList = new BehaviorSubject<FilterList<any>>({
    title: 'statistics.filters.ad_format',
    key: filtersListsKey.adFormats,
    data: [],
    multiple_search: true,
    groupBy: 'ads',
    placeholder: 'statistics.filters.ad_format_place_holder',
  });

  public countryList = new BehaviorSubject<FilterList<any>>({
    title: 'statistics.filters.country',
    key: filtersListsKey.countries,
    data: [],
    multiple_search: true,
    groupBy: 'selectedAllGroup2',
    placeholder: 'statistics.filters.country_place_holder',
  });

  private countries = new BehaviorSubject<Item[]>([]);
  public countries$ = this.countries.asObservable();

  private selected = new BehaviorSubject<any>({
    ad_formats: this.adFormatsList.value.data,
  });
  public selected$ = this.selected.asObservable();

  public lists$: Observable<FilterList<any>[]> = combineLatest([
    this.adFormatsList.asObservable(),
  ]).pipe(
    map(
      ([adFormatsList]) => [
        adFormatsList,
      ]
    )
  );

  private adFormatColumnsMap = new BehaviorSubject<{ [key: string]: string }>(
    {}
  );
  public adFormatColumnsMap$ = this.adFormatColumnsMap.asObservable();

  private btnDisabled = new BehaviorSubject<boolean>(true);
  public btnDisabled$ = this.btnDisabled.asObservable();

  getFilters(report = true) {
    const url = this.apiUrl + this.appConfig.actions.statisticsFilters;
    const headers = this.loginService.TokenFromStorage;
    this.http.get(url, { headers }).subscribe(
      (res: any) => {
        this.statisticsService.setMapsValue(res.message);
        this.linkList.next({
          ...this.linkList.value,
          data: objectToArray(res.message.link_id_to_name_map),
        });

        this.placementReferralList.next({
          ...this.placementReferralList.value,
          data: objectToArray(res.message.ref_id_to_ref_name_map),
        });

        this.placementList.next({
          ...this.placementList.value,
          data: objectToArray(res.message.tag_id_to_tag_name_map),
        });

        this.adFormatsList.next({
          ...this.adFormatsList.value,
          data: objectToArray(res.message.ad_formats_id_to_name),
        });

        this.adFormatsIdTags = res.message.ad_format_id_to_tag_id;

        const lists = {
          links: this.linkList.value.data,
          tags: this.placementList.value.data,
          countries_list: this.countries.value,
          ad_formats: this.adFormatsList.value.data,
          referral: this.placementReferralList.value.data,
        };

        localStorage.setItem('linkL', JSON.stringify(lists.links.length));

        this.selected.next(lists);
        this.addRemoveFilters(this.selected.value.ad_formats);

        if (report) {
          this.getReport();
        }
      },
      (error: HttpErrorResponse) => {
        return this.generalService.catchError(
          snackbarObjectFailedFilters,
          error
        );
      }
    );
  }

  getSelectedValue() {
    return this.selected.value;
  }

  getUserColums() {
    const url = this.apiUrl + this.appConfig.actions.userColumns;
    const headers = this.loginService.TokenFromStorage;
    this.http.get(url, { headers }).subscribe(
      (res: any) => {
        if (res) {
          this.adFormatColumnsMap.next(res.message);
        }
      },
      (error: HttpErrorResponse) => {
        return this.generalService.catchError(
          snackbarObjectFailedColumns,
          error
        );
      }
    );
  }

  getReport() {
    const hasCountry = this.getHasCountry();
    const numOfCountries = this.numOfTotalCountries;
    this.statisticsService.getReport(hasCountry, numOfCountries);
  }

  getHasCountry() {
    return this.hasCountry.value;
  }

  navigateFrom(items: Item[], key: keyof Filter) {
    let adFormats: Item[] = [];
    switch (key) {
      case filtersListsKey.tags:
        adFormats = [
          {
            key: '52',
            value: 'Rewarded JS',
          },
        ];
        break;
      case filtersListsKey.links:
        adFormats = [
          {
            key: '51',
            value: 'Content Locker',
          },
        ];
        break;
      case filtersListsKey.placement:
        adFormats = [
          {
            key: '57',
            value: 'Referral',
          },
        ];
        break;
    }
    this.changesAds(adFormats, items);
  }

  chooseFromList(items: Item[], key: keyof Filter) {
    if (items && items.length > 0) {
      this.btnDisabled.next(false);
    }
    switch (key) {
      case filtersListsKey.tags:
        this.updateTagsFilter(items);
        break;
      case filtersListsKey.links:
        this.updateLinkFilter(items);
        break;
      case filtersListsKey.adFormats:
        this.changesAds(items);
        break;
      case filtersListsKey.countries:
        this.updateCountryFilter(items);
        break;
    }
  }

  removeFromList(items: Item[], key: keyof Filter, selected: Item[]) {
    if (items && items.length > 0) {
      this.btnDisabled.next(false);
    }
    if (selected.length === 0) {
      this.btnDisabled.next(true);
    }
    switch (key) {
      case filtersListsKey.tags:
        this.removePlacement(selected);
        break;
      case filtersListsKey.links:
        this.removeLink(selected);
        break;
      case filtersListsKey.adFormats:
        this.removeAdFormat(items, selected);
        break;
      case filtersListsKey.countries:
        this.updateCountryFilter(selected);
        break;
    }
  }

  removePlacement(selectedItems: Item[]) {
    this.selected.next({
      ...this.selected.value,
    });

    this.statisticsService.updateFilters({
      tags: this.convertToFilter(selectedItems),
    });
  }

  removeLink(selectedItems: Item[]) {
    this.selected.next({
      ...this.selected.value,
    });

    this.statisticsService.updateFilters({
      links: this.convertLinksToFilter(selectedItems),
    });
  }

  removeAdFormat(ads: Item[], selectedItems: Item[]) {
    let updatedTag = [];

    if (ads.length !== this.adFormatsList.value.data.length) {
      this.btnDisabled.next(false);
    } else {
      updatedTag = [];
      this.btnDisabled.next(true);
    }

    this.selected.next({
      ...this.selected.value,
    });

    this.statisticsService.updateFilters({
      ad_formats: this.convertToFilter(selectedItems),
    });

    this.addRemoveFilters(this.selected.value.ad_formats);
  }

  changesAds(items: Item[], adds: Item[] = []) {
    this.selected.next({
      ...this.selected.value,
      ad_formats: items,
    });

    this.statisticsService.updateFilters({
      ad_formats: this.convertToFilter(items),
    });

    this.addRemoveFilters(items, adds);
  }

  convertLinksToFilter(items: Item[]) {
    if (items && items.length > 0) {
      const uniqueItems = Array.from(
        new Set(items.map((item: any) => item.key))
      );

      return uniqueItems;
    }
    return [];
  }

  convertToFilter(items: Item[]) {
    if (items && items.length > 0) {
      const uniqueItems = Array.from(
        new Set(items.map((item: any) => parseInt(item.key)))
      );

      return uniqueItems;
    }
    return [];
  }

  getAdsValue(adsKey: string) {
    const foundItem = this.adFormatsList.value.data.find(
      (item) => item.key === adsKey
    );
    return foundItem ? foundItem.value : null;
  }

  addRemoveFilters(ads: Item[], adds: Item[] = []) {
    this.selected.next({
      ...this.selected.value,
      ad_formats: ads,
    });

    this.statisticsService.updateFilters({
      ad_formats: this.convertToFilter(ads),
    });

    let isCountry = false;
    const hasKey52 = this.selected.value.ad_formats.some(
      (format: any) => format.key === '52'
    );

    const hasKey57 = this.selected.value.ad_formats.some(
      (format: any) => format.key === '57'
    );

    const hasKey51 = this.selected.value.ad_formats.some(
      (format: any) => format.key === '51'
    );

    if (ads && ads.length > 0) {
      isCountry = ads.every(
        (item: Item) =>
          this.adFormatColumnsMap.value[item.key] === 'country_code'
      );

      this.hasCountry.next(isCountry);
    }

    if (ads.length === 0) {
      this.hasCountry.next(false);
    }

    const getObservableLists = () => {
      let observables = [this.adFormatsList.asObservable()];

      if (this.hasCountry.value) {
        observables.push(this.countryList.asObservable());
      }

      if (hasKey52 && !hasKey51 && !hasKey57 && this.placementList.value.data.length > 0) {
        observables.push(this.placementList.asObservable());

        this.updateTagsFilter(
          adds.length > 0 ? adds : (this.placementList.value.data as Item[])
        );
      } else if (hasKey51 && !hasKey52 && !hasKey57 && this.linkList.value.data.length > 0) {
        this.updateLinkFilter(
          adds.length > 0 ? adds : (this.linkList.value.data as Item[])
        );
        observables.push(this.linkList.asObservable());
      } else if (hasKey57 && !hasKey51 && !hasKey52 && this.placementReferralList.value.data.length > 0) {
        this.updateTagsFilter(
          adds.length > 0
            ? adds
            : (this.placementReferralList.value.data as Item[])
        );
        observables.push(this.placementReferralList.asObservable());
      } else {
        this.updateLinkFilter([]);
        this.updateTagsFilter([]);
      }

      return observables;
    };

    this.lists$ = combineLatest(getObservableLists()).pipe(
      map((lists) => lists)
    );

    if (this.hasCountry.value) {
      this.updateCountryFilter(this.countries.getValue());
    }
  }

  updateCountryFilter(items: Item[]) {
    this.selected.next({
      ...this.selected.value,
      countries_list: items,
    });

    this.statisticsService.updateFilters({
      countries_list: this.convertCountry(items),
    });
  }

  updateLinkFilter(items: Item[]) {
    this.selected.next({
      ...this.selected.value,
      links: items,
      tags: [],
    });

    this.statisticsService.updateFilters({
      links: this.convertLinksToFilter(items),
      tags: this.getTagsFromFormats(),
    });
  }

  getTagsFromFormats() {
    let formats = this.selected.value.ad_formats;
    let keys: string[] = [];

    formats.forEach((format: any) => {
      if (format.key) {
        keys.push(format.key);
      }
    });

    if (keys.length < 2) {
      return [];
    } else {
      let combinedIds: number[] = [];
      const idTags = this.adFormatsIdTags as { [key: string]: number[] };
      keys.forEach(key => {
        if (idTags[key]) {
          combinedIds = combinedIds.concat(idTags[key]);
        }
      });
      return combinedIds;
    }
  }


  updateTagsFilter(items: Item[]) {
    if (items.length === 0) {
      this.setBtnDisabled(true);
    }
    this.selected.next({
      ...this.selected.value,
      tags: items,
      links: [],
    });

    this.statisticsService.updateFilters({
      // tags: this.convertLinksToFilter(items),
      links: [],
    });
  }

  convertCountry(items: Item[]) {
    if (items && items.length > 0) {
      const countryList = items.map((item: any) => item.key);
      return countryList;
    }
    return [];
  }

  loadCountries() {
    this.facade.enums$.pipe(first((val) => !!val)).subscribe(
      (state) => {
        const loadedCountries = loadCountries(state);

        this.countryList.next({
          ...this.countryList.value,
          data: loadedCountries,
        });
        this.countries.next(loadedCountries);
        this.selected.next({
          ...this.selected.value,
          countries_list: loadedCountries,
        });

        this.numOfTotalCountries = loadedCountries.length;
        return loadedCountries;
      },
      (error: HttpErrorResponse) => {
        this.loaderService.hide();
      }
    );
    return [];
  }

  setBtnDisabled(isDisable: boolean) {
    this.btnDisabled.next(isDisable);
  }
}
