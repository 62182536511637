<div [formGroup]="createLinkForm" class="create-single-link-container">
  <div>
    <div class="title typography-footnote2-bold">
      {{ 'single.step_1.source_name_title' | translate }}
    </div>
    <div class="subtitle typography-footnote2-thin">
      {{ 'single.step_1.source_name_subtitle' | translate }}
    </div>
    <div class="form-container">
      <div class="form-content">
        <div class="col">
          <div class="line">
            <app-input
              class="name"
              formControlName="short_name"
              [maxLength]="30"
              [textCounter]="true"
              [placeholder]="
                'single.step_1.source_name_placeholder' | translate
              "
              [invalid]="
                (createLinkForm.get('short_name')?.hasError('minlength') &&
                  !createLinkForm.get('short_name')?.pristine) ||
                (createLinkForm.get('short_name')?.hasError('required') &&
                  !createLinkForm.get('short_name')?.pristine)
              "
            ></app-input>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="destination-url">
    <div class="title typography-footnote2-bold">
      {{ 'single.step_1.url_title' | translate }}
    </div>
    <div class="subtitle typography-footnote2-thin">
      {{ 'single.step_1.url_subtitle' | translate }}
    </div>
    <div class="form-container">
      <div class="form-content">
        <div class="col">
          <div class="line">
            <app-input
              [disabled]=isEditMode
              class="url"
              formControlName="url"
              [placeholder]="'single.step_1.url_placeholder' | translate"
              [invalid]="
                createLinkForm.get('url')?.hasError('pattern') ||
                (createLinkForm.get('url')?.hasError('required') &&
                  createLinkForm.get('url')?.dirty)
              "
            ></app-input>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="title typography-footnote2-bold">
      {{ 'single.step_1.category_title' | translate }}
    </div>
    <div class="subtitle typography-footnote2-thin">
      {{ 'single.step_1.category_subtitle' | translate }}
    </div>
    <div class="form-container">
      <div class="form-content">
        <div class="col">
          <div class="line">
            <ng-select
              class="dropdown typography-body3-thin"
              [multiple]="false"
              [items]="categories"
              [searchable]="false"
              bindLabel="value"
              bindValue="key"
              formControlName="category"
              [ngClass]="{
                'has-value': createLinkForm.get('category') !== null
              }"
              [class.error]="
                createLinkForm.get('category')?.hasError('required')
              "
              [placeholder]="
                'single.step_1.category_ng_select_placeholder' | translate
              "
            >
            </ng-select>
            <app-input
              *ngIf="createLinkForm.get('category')?.value === 'Create Folder'"
              class="additional-input"
              formControlName="other_category"
              [placeholder]="
                'single.step_1.category_input_placeholder' | translate
              "
              [invalid]="
                createLinkForm.get('other_category')?.hasError('required') &&
                !createLinkForm.get('other_category')?.pristine
              "
            ></app-input>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-button
    class="next-button"
    color="primary"
    [disabled]="submitValidation()"
    (buttonClick)="closeFirstAccordion()"
  >
    {{ 'single.step_1.next' | translate }}
  </app-button>
</div>
