<div *ngIf="thereIsData; else emptyState" class="table-container">
  <ngx-datatable
    #table
    class="table typography-body4-thin"
    [rows]="referralService.rows$ | async"
    [columns]="(referralService.columns$ | async) || []"
    [headerHeight]="50"
    [footerHeight]="50"
    rowHeight="auto"
    columnMode="flex"
    [swapColumns]="false"
    [limit]="limit"
    [offset]="referralService.currentPage - 1"
  >
    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
        <div class="footer-container">
          <div class="pager-container">
            <app-button
              [ngClass]="{ 'icon-disabled-style': curPage <= 1 }"
              color="naked"
              (buttonClick)="pager.prevPage()"
            >
              <app-icon start name="navigation_left"></app-icon>
            </app-button>
            <datatable-pager
              #pager
              [page]="referralService.currentPage"
              [size]="limit"
              [count]="rowCount"
              (change)="changePage($event) && table.onFooterPage($event)"
            ></datatable-pager>
            <app-button
              [ngClass]="{
                'icon-disabled-style': curPage === numOfPages
              }"
              color="naked"
              (buttonClick)="pager.nextPage()"
            >
              <app-icon start name="navigation_right"></app-icon>
            </app-button>
          </div>
          <ng-select
            class="pagination"
            [ngModel]="limit"
            (ngModelChange)="referralService.setChosenLimit($event)"
            [items]="referralService.limitOptions"
            appendTo="body"
            [clearable]="false"
            [searchable]="false"
          ></ng-select>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<ng-template
  #actions
  let-value="value"
  let-row="row"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
>
  <div class="site-actions" *ngIf="isDesktop">
    <div (click)="navigateToStatistics(row)" class="action">
      <app-icon size="lg" name="analytics"></app-icon>
    </div>
  </div>
</ng-template>

<ng-template #emptyState>
  <app-empty-state
    [subtitle]="'referral_program.empty_state'"
  ></app-empty-state>
</ng-template>
