import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AddMultipleService } from '../../service/add-multiple.service';
import { Item } from 'src/app/shared/types/item';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { checkCategoryValidation } from 'src/app/shared/helper/helper';
import {DashboardService} from "../../../../dashboard/service/dashboard.service";

@Component({
  selector: 'set-up',
  templateUrl: './set-up.component.html',
  styleUrls: ['./set-up.component.scss'],
})
export class SetUpComponent implements OnInit {
  @Input() setUpForm!: FormGroup;
  @Output() continue = new EventEmitter<any>();
  categories!: Item[];
  //TO DO: check the link
  public link = 'https://d3h26c51lqz4go.cloudfront.net/loot-sources/bulkEx.png';

  constructor(
    private addMultipleService: AddMultipleService,
    private generalService: GeneralService,
    public dashboardService: DashboardService,

  ) {}

  ngOnInit(): void {
    this.dashboardService.getCategory().subscribe((val) => {
      const firstCategory: Item = {
        key: 'Other',
        value: 'Other'
      };
      val.message.unshift(firstCategory);
      const lastCategory: Item = {
        key: 'Create Folder',
        value: 'Create Folder'
      };
      val.message.push(lastCategory);
      this.categories = val.message.filter((category: Item) => category.value !== '');
    });
    this.setUpForm.get('category')?.valueChanges.subscribe((value) => {
      this.checkCategoryValidation(value);
    });

    // this.setUpForm.valueChanges.subscribe(() => {
    //   this.steps[0].isValid = this.firstFormGroup.valid && this.isStep1Next;
    // });
  }

  downloadFile() {
    this.addMultipleService.downloadFile();
  }

  checkCategoryValidation(value: string) {
    checkCategoryValidation(value, this.setUpForm);
  }

  next() {
    this.continue.emit();
  }
}
