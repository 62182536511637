<div class="header">
  <div class="left">
    <div (click)="navigateToHomePage()" class="logo">
      <app-logo></app-logo>
    </div>
    <app-button *ngIf="!isDesktop" [matMenuTriggerFor]="menu" color="secondary">
      <app-icon name="add"></app-icon>
      {{ "header.menu" | translate }}
    </app-button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="createContentLocker()">{{ "header.create" | translate }}</button>
      <button mat-menu-item (click)="createApiKey()">{{ "header.api_key" | translate }}</button>
      <a mat-menu-item routerLink="/referral-program"  (click)="(navigation('/referral-program'))">{{ "header.referral" | translate }}</a>
    </mat-menu>
    <div *ngIf="isDesktop">
      <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        <a
          mat-tab-link
          [routerLink]="link.route"
          *ngFor="let link of links"
          [active]="activeLink.includes(link.route)"
          [class.focus]="activeLink.includes(link.route)"
          (click)="navigation(link.route)"
        >
          <span class="title typography-footnote2-thin2">{{
            link.title | translate
          }}</span>
        </a>
      </nav>
      <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    </div>
  </div>
  <div class="right">
<!--    <app-button-->
<!--      style="margin: auto;transition: background-color 150ms linear;border: none;"-->
<!--      *ngIf="isDesktop && isOldUser"-->
<!--      class="button-flex"-->
<!--      size="md"-->
<!--      (buttonClick)="redirectToOld()"-->
<!--      color="primary"-->
<!--    >-->
<!--      {{"old_panel.button" | translate}}-->
<!--    </app-button>-->
    <div class="balance time-zone" *ngIf="isDesktop">
      <span class="typography-headline2">
        {{ date }} {{ 'header.timezone' | translate }}</span
      >
    </div>
    <div class="header-buttons-container" [ngClass]="{ mobile: !isDesktop }">
      <app-button
        *ngIf="!isDesktop"
        (buttonClick)="navigateStatistics()"
        customStyle="header-button-size"
        color="naked"
      >
        <app-icon name="statistics" size="lg" start></app-icon>
      </app-button>
      <app-button
        (buttonClick)="openSupport()"
        customStyle="header-button-size"
        color="naked"
      >
        <app-icon name="question_mark" size="md" start></app-icon>
      </app-button>
      <app-button
        (buttonClick)="openNotifications()"
        customStyle="header-button-size"
        color="naked"
      >
        <app-icon
          size="md"
          matBadge="{{ unseenNotificationsCount }}"
          matBadgeOverlap="true"
          matBadgeColor="warn"
          [matBadgeHidden]="hideNewNotificationsCounter()"
          name="notifications"
          start
        ></app-icon>
      </app-button>
      <app-button
        color="naked"
        class="profile-image-box"
        [ngClass]="{ 'mobile-profile-image-box': !isDesktop }"
        customStyle="header-button-size"
        id="avatarButton"
        [popoverTrigger]="avatarDrawer"
      >
        <img
          *ngIf="profilePicture"
          [src]="profilePicture"
          class="profile-image"
        />
      </app-button>
    </div>
  </div>
</div>

<ng-template #avatarDrawer>
  <popover-container class="avatar-drawer-container-popover">
    <ng-container>
      <div class="avatar-drawer-container">
        <div class="user-details">
          <div class="user-details-container">
            <img
              *ngIf="profilePicture"
              [src]="profilePicture"
              class="profile-image"
            />
            <div class="details">
              <div class="subtitle typography-body2-bold">
                {{ creatorName }}
              </div>
              <div class="email typography-body4-thin">
                {{ creatorEmail }}
              </div>
            </div>
          </div>
        </div>
        <div class="avatar-drawer-buttons-container">
          <a
            *ngIf="isDesktop"
            color="naked"
            (click)="goToProfile()"
            class="popover-element padding"
            [routerLink]="'/profile'"
          >
            <app-icon start name="account" size="md"></app-icon>
            <div class="typography-body4-thin buttons-text">
              {{ 'header.profile' | translate }}
            </div>
          </a>
          <app-divider
            class="divider-drawer"
            direction="horizontal"
            customStyle="condensed"
          ></app-divider>
          <app-button
            color="naked"
            customStyle="header-drawer-buttons"
            (buttonClick)="logout()"
            class="padding"
          >
            <app-icon start name="logout" size="sm"></app-icon>
            <div class="typography-body4-thin buttons-text">
              {{ 'header.logout' | translate }}
            </div>
          </app-button>
        </div>
      </div>
    </ng-container>
  </popover-container>
</ng-template>
