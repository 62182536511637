import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PaymentDetailsModel} from '../../models/payment_details';
import {Item} from 'src/app/shared/types/item';
import {PaymentMethodFormService} from './service/payment-method-form.service';
import {atLeastOneCompleted} from 'src/app/shared/validators/validatiors';
import {UserModel} from "../../../../common/login/store/models/user.model";

@Component({
  selector: 'app-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  styleUrls: ['./payment-method-form.component.scss'],
})
export class PaymentMethodFormComponent implements OnChanges {
  @Input() selectedKey!: string;
  @Input() paymentMethodForm!: FormGroup;
  @Input() paymentMethodList!: Item[];
  @Input() paymentDetails!: PaymentDetailsModel | null;
  @Input() userDetails!: UserModel;

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    const focusedElement = document.activeElement;
    if (
      focusedElement instanceof HTMLInputElement &&
      focusedElement.type === 'number'
    ) {
      event.preventDefault();
    }
  }

  public countryChoose = '';
  disableCities!: boolean;

  constructor(
    private fb: FormBuilder,
    public paymentMethodFormService: PaymentMethodFormService
  ) {
  }

  ngOnChanges(): void {
    this.paymentMethodFormService.loadCountries();
    this.updateFormControls(this.selectedKey);

    this.paymentMethodForm
      .get('payment_method')
      ?.valueChanges.subscribe((selectedValue) => {
      this.selectedKey = this.paymentMethodList
        ? this.paymentMethodList.find((item) => item.value === selectedValue)
        ?.key ?? ''
        : '';

      this.updateFormControls(this.selectedKey);
    });
  }

  onCountryChanged(country: string) {
    this.countryChoose = country;

    if (!country) {
      this.disableCities = true;
      this.paymentMethodFormService.loadCities('');
      this.paymentMethodForm.get('bank_city')?.setValue(null);
      return;
    }
    this.paymentMethodForm.get('bank_city')?.setValue(null);
    this.disableCities = false;
    this.paymentMethodFormService.loadCities(country);
  }

  checkCrypto(): boolean {
    return this.userDetails?.crypto_allowed_status === 'approved';
  }

  updateFormControls(selectedValue: string | null): void {
    this.paymentMethodForm.removeControl('bank_name');
    this.paymentMethodForm.removeControl('account_number');
    this.paymentMethodForm.removeControl('iban_number');
    this.paymentMethodForm.removeControl('comment');
    this.paymentMethodForm.removeControl('swift_code');
    this.paymentMethodForm.removeControl('verification_image');
    this.paymentMethodForm.removeControl('bank_address');
    this.paymentMethodForm.removeControl('payment_email');
    this.paymentMethodForm.removeControl('bank_country');
    this.paymentMethodForm.removeControl('bank_city');

    if (this.paymentMethodForm) {
      if (selectedValue === '2') {
        this.paymentMethodForm.addControl(
          'bank_name',
          this.fb.control(this.paymentDetails?.bank_name, Validators.required)
        );
        this.paymentMethodForm.addControl(
          'account_number',
          this.fb.control(this.paymentDetails?.account_number)
        );
        this.paymentMethodForm.addControl(
          'iban_number',
          this.fb.control(this.paymentDetails?.iban_number)
        );
        this.paymentMethodForm.addControl(
          'comment',
          this.fb.control(this.paymentDetails?.comment)
        );
        this.paymentMethodForm.addControl(
          'swift_code',
          this.fb.control(this.paymentDetails?.swift_code, Validators.required)
        );
        this.paymentMethodForm.addControl(
          'bank_address',
          this.fb.control(
            this.paymentDetails?.bank_address,
            Validators.required
          )
        );
        this.paymentMethodForm.addControl(
          'bank_country',
          this.fb.control(
            this.paymentDetails?.bank_country,
            Validators.required
          )
        );
        this.paymentMethodForm.addControl(
          'bank_city',
          this.fb.control(this.paymentDetails?.bank_city, Validators.required)
        );
        const controlsToValidate = ['iban_number', 'account_number'];

        this.paymentMethodForm.setValidators(
          atLeastOneCompleted(controlsToValidate)
        );

        this.updateCountryAndCity();
      }
      if ((selectedValue === '4' || selectedValue === '10') && this.checkCrypto()) {
        this.paymentMethodForm.addControl('payment_email', this.fb.control(this.paymentDetails?.payment_email, Validators.required));
        // this.paymentMethodForm.addControl('verification_image', this.fb.control(this.paymentDetails?.verification_image, Validators.required));
      } else if ((selectedValue === '4' || selectedValue === '10') && !this.checkCrypto()) {
        this.paymentMethodForm.addControl('verification_image', this.fb.control(this.paymentDetails?.verification_image, Validators.required));
      } else {
        this.paymentMethodForm.addControl('payment_email', this.fb.control(this.paymentDetails?.payment_email, Validators.required));
      }
    }
  }

  updateCountryAndCity() {
    this.paymentMethodForm
      .get('bank_country')
      ?.valueChanges.subscribe((val) => {
      this.onCountryChanged(val);
    });
  }

  isWallet(): boolean {
    return (
      this.selectedKey === '9' ||
      this.selectedKey === '4' ||
      this.selectedKey === '6' ||
      this.selectedKey === '7' ||
      this.selectedKey === '10'
    );
  }
}
