import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { Links } from './constants/links';
import { UserModel } from '../../login/store/models/user.model';
import { createFullName } from 'src/app/shared/helper/helper';
import { LoginService } from '../../login/store/login-state/login.service';
import { CreatorModel } from '../../login/store/models/creator.model';
import { NotificationsService } from 'src/app/shared/components/notifications/services/notifications.service';
import { NotificationModel } from 'src/app/shared/components/notifications/models/notifications.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  @Output() onNotificationsClick = new EventEmitter<void>();
  @Output() onSupportClick = new EventEmitter<void>();
  public unseenNotificationsCount!: number;
  public isDesktop!: boolean;
  public date!: Date | string;
  public timeZone!: number;
  public isOldUser!: boolean;
  public profilePicture!: any;
  public links!: { title: string; route: string }[];
  activeLink!: string;
  public creator = new CreatorModel();
  public creatorName!: string;
  public creatorEmail!: string;

  constructor(
    private router: Router,
    private generalService: GeneralService,
    private cdr: ChangeDetectorRef,
    private loginService: LoginService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.isOldUser = !!localStorage.getItem('oldUser');
    this.links = Links;
    this.getIsDesktop();
    this.setRoute();
    this.initNotifications();
    this.getUserDetails();
    this.router.events.subscribe(() => {
      this.setRoute();
    });
  }

  openSupport() {
    this.onSupportClick.emit();
  }

  initNotifications() {
    this.notificationsService.notificationsData$.subscribe(
      (res: Array<NotificationModel>) => {
        this.unseenNotificationsCount = res.filter(
          (notification) => !notification.seen
        ).length;
      }
    );
  }

  navigateStatistics() {
    this.router.navigate(['/statistics']);
  }

  ngAfterViewChecked() {
    this.setLocalTime();
    this.cdr.detectChanges();
  }

  goToProfile() {
    this.closePopOver();
    this.router.navigate(['profile']).then();
  }

  openNotifications() {
    this.onNotificationsClick.emit();
  }

  closePopOver() {
    const overlayElement = document.getElementById('popover-overlay');
    if (overlayElement) {
      overlayElement.remove();
    }
  }

  logout() {
    this.closePopOver();
    this.loginService.logoutGuard();
    this.router.navigate(['/login']);
  }

  getUserDetails(): void {
    this.generalService.creator$.subscribe((creator: CreatorModel | null) => {
      this.profilePicture =
        creator?.creator_picture === '' || creator?.creator_picture === null
          ? './assets/svg/placeholder_image.svg'
          : creator?.creator_picture;

      this.creatorName = creator?.creator_name || '';
      this.creatorEmail = creator?.publisher_username || '';
    });
  }

  navigation(route: string) {
    this.activeLink = route;
  }

  createFullName(firstName: string | null, lastName: string | null) {
    return createFullName(firstName, lastName);
  }

  setLocalTime(): void {
    this.date = new Date();
    this.timeZone = this.date.getTimezoneOffset() / 60;
    this.date.setHours(this.date.getHours() + this.timeZone);
    this.date = this.date.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  setRoute() {
    this.activeLink = this.router.url;
  }

  getIsDesktop(): void {
    this.generalService.responsive();
    this.generalService.isDesktop$.subscribe((val) => {
      this.isDesktop = val;
    });
  }

  redirectToOld(): void {
    window.location.href = 'https://creators-old.lootlabs.gg/#/login?useOld=true';
  }

  createContentLocker() {
    this.router.navigate(['single-link']);
  }

  hideNewNotificationsCounter(): boolean {
    return this.unseenNotificationsCount === 0;
  }

  createApiKey() {
    this.router.navigate(['api-key']);
  }

  navigateToHomePage() {
    this.router.navigate(['/dashboard']);
  }
}
