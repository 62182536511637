<div [class.padding-profit]="isDesktop">
  <div class="my-profit-container">
    <div class="titles-container">
      <span class="title typography-heading2">{{
        'my_profit.title' | translate
      }}</span>
      <span class="time typography-body4-thin"
        >{{ 'my_profit.last_update' | translate }} {{ updatedOn }}</span
      >
    </div>
    <swiper
      *ngIf="!isDesktop; else Desktop"
      [slidesPerView]="1.15"
      [navigation]="true"
      [pagination]="{ clickable: true }"
    >
      <ng-template swiperSlide *ngFor="let item of myProfitData">
        <div class="card">
          <span class="title typography-body4-thin">
            {{ item.title | translate }}
          </span>
          <div style="display: flex;align-items: center;justify-content: space-between;">
            <span class="profit typography-heading3-bold">{{
                item.profit
              }}</span>
            <span *ngIf="item.subtitle" class="subtitle typography-body3-thin " style="font-size: 12px">
            {{ item.subtitle | translate }}
          </span>
          </div>
        </div>
      </ng-template>
    </swiper>
    <ng-template #Desktop>
      <div class="cards-container">
        <ng-container *ngFor="let item of myProfitData">
          <div class="card" tooltip="{{item.tooltip | translate}}">
            <span class="title typography-body4-thin">{{
              item.title | translate
            }}</span>
            <div style="display: flex;align-items: center;justify-content: space-between;">
            <span class="profit typography-heading3-bold">{{
              item.profit
            }}</span>
            <span *ngIf="item.subtitle" class="subtitle typography-body3-thin " style="font-size: 12px">
            {{ item.subtitle | translate }}
          </span>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>
